@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .menu-entry {
    @apply w-full block text-left px-4 py-2 text-sm text-gray-500 hover:bg-zinc-300 hover:text-gray-600 duration-300
  }

}
