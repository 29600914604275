@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .base-class {
    @apply hover:rounded-xl border-x-2 p-2 drop-shadow-xl opacity-75 hover:opacity-100 duration-300 cursor-pointer hover:text-white;
  }
  .facebook {
    @apply border-blue-500 hover:bg-blue-500 text-blue-400;
  }
  .instagram {
    @apply border-fuchsia-600 hover:bg-fuchsia-600 text-fuchsia-400;
  }
  .discord {
    @apply border-indigo-600 hover:bg-indigo-600 text-indigo-400;
  }
  .youtube {
    @apply border-red-600 hover:bg-red-600 text-red-400;
  }
}
