@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --base-grey: #332e2e;
  --base-green: #00BFA5;
  --secondary-green: #69F0AE;
  --accent-color: #1976D2;
  --accent-red: ${parseToRgb('#1976D2').red};
  --accent-green: ${parseToRgb('#1976D2').green};
  --accent-blue: ${parseToRgb('#1976D2').blue};
  --custom-shadow: rgba(var(--accent-red), var(--accent-green), var(--accent-blue), 0.5);
  --discord-base: #5865F2;
}

* {
/* transition: all 0.15s ease-in; */
}

body {
  background-image: linear-gradient(
      216deg,
      rgba(77, 77, 77, 0.05) 0%,
      rgba(77, 77, 77, 0.05) 25%,
      rgba(42, 42, 42, 0.05) 25%,
      rgba(42, 42, 42, 0.05) 38%,
      rgba(223, 223, 223, 0.05) 38%,
      rgba(223, 223, 223, 0.05) 75%,
      rgba(36, 36, 36, 0.05) 75%,
      rgba(36, 36, 36, 0.05) 100%
    ),
    linear-gradient(
      44deg,
      rgba(128, 128, 128, 0.05) 0%,
      rgba(128, 128, 128, 0.05) 34%,
      rgba(212, 212, 212, 0.05) 34%,
      rgba(212, 212, 212, 0.05) 57%,
      rgba(25, 25, 25, 0.05) 57%,
      rgba(25, 25, 25, 0.05) 89%,
      rgba(135, 135, 135, 0.05) 89%,
      rgba(135, 135, 135, 0.05) 100%
    ),
    linear-gradient(
      241deg,
      rgba(55, 55, 55, 0.05) 0%,
      rgba(55, 55, 55, 0.05) 14%,
      rgba(209, 209, 209, 0.05) 14%,
      rgba(209, 209, 209, 0.05) 60%,
      rgba(245, 245, 245, 0.05) 60%,
      rgba(245, 245, 245, 0.05) 69%,
      rgba(164, 164, 164, 0.05) 69%,
      rgba(164, 164, 164, 0.05) 100%
    ),
    linear-gradient(
      249deg,
      rgba(248, 248, 248, 0.05) 0%,
      rgba(248, 248, 248, 0.05) 32%,
      rgba(148, 148, 148, 0.05) 32%,
      rgba(148, 148, 148, 0.05) 35%,
      rgba(202, 202, 202, 0.05) 35%,
      rgba(202, 202, 202, 0.05) 51%,
      rgba(181, 181, 181, 0.05) 51%,
      rgba(181, 181, 181, 0.05) 100%
    ),
    linear-gradient(92deg, hsl(214, 0%, 11%), hsl(214, 0%, 11%));
}

.app-container {
  width: 100vw;
}

.card {
  background-color: rgba(255,255,255,0.5);
  border: 2px solid transparent;
  transition: all 0.35s ease;
  -webkit-box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);
  -moz-box-shadow:  1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);

  /* ;
  border-radius: 5px;
   */
}

.card:hover{
  border: 2px solid var(--accent-color);
  background-color: rgba(255,255,255,0.75);
  -webkit-box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.7);
  -moz-box-shadow:  0px 0px 8px 3px rgba(0, 0, 0, 0.7);
  box-shadow: 0px 0px 8px 3px var(rgba(0, 0, 0, 0.7));
  /*


  -webkit-box-shadow: 0px 0px 8px 3px var(--custom-shadow);
  -moz-box-shadow: 0px 0px 8px 3px var(--custom-shadow);
  box-shadow: 0px 0px 8px 3px var(--custom-shadow); */
}
.custom-height {
  min-height: calc(100dvh - 104px);
  max-height: calc(100dvh - 104px);
  height: calc(100dvh - 104px);
  overflow-y: auto;
  overflow-x: hidden;
}
.custom-matches-height {
  min-height: 300px;
  max-height: calc(100dvh - 512px);
  /* height: calc(100dvh - 400px); */
  overflow-y: auto;
  overflow-x: hidden;
}
.button-tab {
  color:#e6e6e6;
  background: transparent;
}
.button-tab__selected {
  background: var(--accent-color);
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: #e6e6e6;
}

::-webkit-scrollbar-thumb {
  background: var(--accent-color);
}
::-webkit-scrollbar-thumb:hover{
  background: var(--accent-color);
}
