@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  * {
    @apply outline-none;
  }
  .remove-button {
    @apply absolute -top-4 -right-4 w-8 h-8 rounded-full bg-red-500 z-50;
  }
  .own-skins {
    @apply relative flex-1 p-4 rounded-lg shadow-lg h-full;
  }
  .own-skins.ct {
    @apply bg-sky-700/20 text-sky-300/50;
  }
  .own-skins.t {
    @apply bg-amber-500/20 text-amber-300/50;
    /* shadow-amber-300; */
  }
  .add-button {
    @apply relative h-32 w-32 border-2 border-dashed border-slate-200/35 rounded-lg flex flex-row items-end justify-center text-lg;
  }
  .add-button.skin {
    @apply items-center px-2 flex-col;
  }
  .add-button.skin p {
    @apply text-xs line-clamp-2;
  }
  .add-button.skin img {
    @apply w-full;
  }
  .add-button svg {
    @apply -translate-y-4;
  }
  .add-button::before {
    @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-3/4 w-full;
    content: attr(data-label);
  }
  .add-button-small {
    @apply relative h-16 w-16 border-2 border-dashed border-slate-200/35 rounded-lg flex flex-row items-center justify-center text-lg;
  }
  .d-panel {
    @apply h-96 border-2 border-stone-900 bg-stone-800 rounded-md flex flex-1 shadow-lg flex-col;
  }
  .panel-title {
    @apply relative text-white bg-stone-900 text-center p-2 border-b-2 border-b-slate-700;
  }
  .panel-title button {
    @apply absolute right-0 top-0 bg-red-400/15 h-full w-10 rounded-r-md rounded-b-none flex items-center justify-center duration-300;
    @apply hover:bg-red-400/25;
  }
  .panel-title button svg {
    @apply h-8;
  }
  .spec-container {
    @apply flex flex-row items-center justify-between p-4;
  }
  .spec-container.start {
    @apply flex flex-row items-center justify-start p-4 gap-x-4;
  }
  .category {
    @apply flex flex-row items-center text-stone-300 gap-x-2 text-sm;
  }
  .category.active {
    @apply text-sky-300;
  }
  .menu-svg {
    @apply duration-500 text-xs;
  }
  .menu-svg.active {
    @apply -rotate-180;
  }
  .weapon-button {
    @apply px-1 text-stone-300 w-full items-center justify-start text-start text-xs;
  }
  .lateral-panel-item {
    @apply text-slate-300 text-start text-xs pb-2 border-b border-b-slate-600 last:border-0;
  }
  .lateral-panel-item.active {
    @apply text-sky-300;
  }
  .grid-container {
    @apply p-2 text-white grid grid-cols-6  gap-x-2 gap-y-8 w-full;
  }
  .content-item {
    @apply flex flex-col items-center justify-center;
  }
  .content-item:hover img {
    @apply opacity-100 grayscale-0;
  }
  .content-item img {
    @apply h-24 w-auto opacity-50 grayscale duration-300;
  }
  .content-item.skin img {
    @apply filter-none;
  }
  .content-item p {
    @apply text-xs;
  }
  .filter-container {
    @apply sticky top-3 z-50 flex flex-row items-center  mx-auto mb-10 gap-x-1 bg-stone-800/75 rounded-sm w-52 px-2 py-1 border border-sky-400;
  }
  .filter-container input {
    @apply bg-transparent w-full outline-none caret-sky-400 text-sky-200;
  }
  .filter-container button {
    @apply text-sky-400;
  }
  .goback-container {
    @apply px-3 mt-3;
  }
  .goback-container button {
    @apply flex flex-row items-center justify-center text-stone-400 gap-x-3 hover:text-stone-200 duration-300;
  }
  .goback-container button svg {
    @apply duration-300;
  }
  .goback-container button:hover svg {
    @apply -translate-x-1;
  }

  .wear.slider-container {
    @apply relative m-0 px-2;
  }
  .wear.slider-container input[type='range'] {
    @apply mx-auto bg-wear-tiers-gradient appearance-none w-full h-2 rounded-md outline-none border-0 overflow-visible;
    -webkit-appearance: none;
  }

  .wear.slider-container input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    @apply appearance-none w-5 h-5 border-2 border-solid border-stone-900 bg-white rounded-full cursor-pointer;
  }

  .wear.slider-container input[type='range']::-moz-range-thumb {
    @apply w-5 h-5 border-2 border-solid border-stone-900 bg-white rounded-full cursor-pointer;
  }

  .premium-content {
    @apply flex flex-col text-xs text-stone-300
    /* @apply absolute end-5 -top-5 flex items-center text-xs text-stone-300; */;
  }
  .premium-content > div {
    @apply flex flex-col italic;
  }
  .premium-content > div > p:first-child::before {
    @apply mr-2 not-italic;
    content: '👑';
  }

  .knob {
    @apply cursor-pointer grayscale text-emerald-400 opacity-0 absolute -left-1 -top-1 h-6 w-6 bg-white rounded-full shadow-md transform
    peer-checked:translate-x-10
    duration-300;
  }
  .knob.minus {
    @apply opacity-100
    peer-checked:opacity-0;
  }
  .knob.checked {
    @apply opacity-0
    peer-checked:opacity-100
    peer-checked:filter-none;
  }
}
