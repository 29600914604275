@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .is-span {
    @apply col-span-1 sm:col-span-2 mx-auto;
  }
  .box-info {
    @apply h-20 w-56 border border-stone-500/60 rounded-md flex flex-col justify-center px-5 bg-zinc-800
  }
  .bi-header {
    @apply text-stone-400 text-xs text-center
  }
  .bi-content {
    @apply text-white text-3xl text-center
  }
  .bi-footer {
    @apply text-stone-400 text-xs text-center mt-2
  }
}
