@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .div-container {
    @apply bg-white/15 border-s-stone-400 border-s-2 h-full w-full rounded-md flex flex-row px-3 py-1 justify-between
    hover:bg-white/25 duration-300
  }
  .button-container {
    @apply text-white
  }

}
